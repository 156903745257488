<template>
  <v-container fluid>
    <v-card>
      <v-card-title>Add Interview Question</v-card-title>
      <v-card-text>
        <v-form v-model="isValid" ref="form">
          <v-row>
            <v-col md="6" cols="12">
              <v-text-field
                label="Interview Title"
                v-model="form.title"
                required
                :rules="fieldRules"
                color="primary"
                class="pr-2"
              >
              </v-text-field>
            </v-col>

            <v-col md="6" cols="12">
              <v-text-field
                label="Interview duration"
                placeholder="Please enter duration in minutes"
                v-model="form.time_duration"
                :rules="fieldRules"
                color="primary"
                class="pr-2"
                type="number"
                required
              >
              </v-text-field>
            </v-col>

            <v-col md="6" cols="12">
              <v-text-field
                label="Interview welcome note"
                v-model="form.welcome_note"
                :rules="fieldRules"
                color="primary"
                class="pr-2"
                required
              >
              </v-text-field>
            </v-col>

            <v-col md="6" cols="12">
              <v-text-field
                label="Interview goodbye note"
                v-model="form.goodbye_note"
                :rules="fieldRules"
                color="primary"
                class="pr-2"
                required
              >
              </v-text-field>
            </v-col>

            <v-col md="12" cols="12">
              <v-text-field
                label="Welcome Video Link"
                placeholder="Please enter video url to display on interview welcome screen"
                v-model="form.intro_video"
                :rules="fieldRules"
                color="primary"
                class="pr-2"
              >
              </v-text-field>
            </v-col>
          </v-row>

          <v-row align="center">
            <v-col cols="3"> Enter new interview question here... </v-col>
            <v-col cols="8">
              <v-text-field
                v-model="question"
                placeholder="Type your question here"
                color="primary"
                class="pr-2"
                hide-details
              >
              </v-text-field>
            </v-col>
            <v-col cols="1">
              <v-btn outlined color="secondary" @click="addMoreQuestion"
                >Add</v-btn
              >
            </v-col>
          </v-row>
          <br />

          <div class="intro-main" v-for="(q, i) in form.questions" :key="i">
            <v-row>
              {{ i + 1 }}.
              <v-col md="2" cols="2">
                <v-text-field
                  v-model="form.questions[i].title"
                  label="Title"
                  class="p-0 m-0 elevation-0"
                >
                </v-text-field>
              </v-col>
              <v-col md="2" cols="2">
                <v-text-field
                  v-model="form.questions[i].reading_time"
                  label="Reading Duration"
                  class="p-0 m-0 elevation-0"
                  type="number"
                  hide-details
                >
                  <template v-slot:prepend>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on"> mdi-help-circle-outline </v-icon>
                      </template>
                      Enter time in seconds
                    </v-tooltip>
                  </template>
                </v-text-field>
              </v-col>
              <v-col md="1" cols="1">
                <v-select
                  hide-details
                  v-model="form.questions[i].screen_share"
                  label="Share a screen (Y/N)"
                  item-text="title"
                  item-value="value"
                  class="mx-0 p-0"
                  :items="[
                    { title: 'Yes', value: false },
                    { title: 'No', value: true },
                  ]"
                >
                </v-select>
              </v-col>
              <v-col md="2" cols="2">
                <v-select
                  hide-details
                  v-model="form.questions[i].question_type"
                  label="Question Type"
                  item-text="title"
                  item-value="value"
                  class="mx-0 p-0"
                  :items="[
                    { title: 'Audio/Video', value: 'audio/video' },
                    { title: 'MCQs', value: 'mcq' },
                    { title: 'Code', value: 'code' },
                  ]"
                >
                </v-select>
              </v-col>
              <v-col md="2" cols="2">
                <v-text-field
                  v-model="form.questions[i].time_duration"
                  label="Answer Time"
                  class="p-0 m-0 elevation-0"
                  type="number"
                  hide-details
                >
                  <template v-slot:prepend>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on"> mdi-help-circle-outline </v-icon>
                      </template>
                      Enter time in seconds
                    </v-tooltip>
                  </template>
                </v-text-field>
                <!-- </div> -->
              </v-col>
              <v-col md="1" cols="1">
                <div class="intro-close pr-0">
                  <v-icon @click="removeQuestion(i)" class="cursor-pointer"
                    >mdi-close</v-icon
                  >
                </div>
              </v-col>
            </v-row>
            <div v-if="form.questions[i].question_type === 'mcq'">
              <v-row
                style="width: 50%"
                v-for="(option, j) in form.questions[i].possibleAnswers"
                :key="j"
                align="center"
              >
                {{ j + 1 }}.
                <v-text-field
                  v-model="form.questions[i].possibleAnswers[j].text"
                  :label="'Option ' + (j + 1)"
                  color="primary"
                  class="pr-2"
                  :rules="fieldRules"
                ></v-text-field>
                <v-checkbox
                  v-model="form.questions[i].possibleAnswers[j].correct"
                  label="Correct"
                  hide-details
                ></v-checkbox>
                <v-icon
                  v-if="form.questions[i].possibleAnswers.length > 1"
                  @click="removeOption(i, j)"
                  class="cursor-pointer"
                  >mdi-close</v-icon
                >
                <v-btn outlined color="secondary" @click="addMoreOption(i)"
                  >Add</v-btn
                >
              </v-row>
            </div>
            <v-row v-if="form.questions[i].question_type === 'code'">
              <v-col cols="12">
                <vue-html5-editor
                  @change="(e) => saveCodeInput(i, e)"
                  :content="form.questions[i].code_input"
                  :height="500"
                ></vue-html5-editor>
              </v-col>
            </v-row>
          </div>

          <div class="text-right mt-5">
            <v-btn color="secondary" @click="onCancelClick"> Cancel</v-btn>
            <v-btn
              color="secondary"
              style="margin: 0px 5px 0px 10px"
              @click="onSaveButtonClick"
              :disabled="!isValid"
            >
              Save Questions
            </v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
    <v-dialog v-model="saveChangesPopUp" max-width="600px">
      <v-card>
        <v-card-text class="pa-3 py-4">
          <template>
            <div xs12 style="width: 100%">
              <!-- <h3>Are you sure you want to save the changes?</h3> -->
              <p>Are you sure you want to save the changes?</p>

              <v-flex class="text-sm-right my-3">
                <v-btn
                  style="margin: 0px 10px 0px 0px"
                  outlined
                  @click="saveChangesPopUp = false"
                  class="buttonPrimary text-lightblue close-modal"
                >
                  No
                </v-btn>
                <v-btn
                  type="submit"
                  class="mr-2"
                  color="secondary"
                  @click="saveQuestion"
                  :disabled="loading"
                  :loading="loading"
                >
                  {{ $vuetify.lang.t("$vuetify.save_changes") }}
                </v-btn>
              </v-flex>
            </div>
          </template>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "add-interview-question",
  data() {
    return {
      fieldRules: [(v) => !!v || "Field is required"],
      isValid: false,
      loading: false,
      question: "",
      saveChangesPopUp: false,
      payload: {},
      form: {
        title: "",
        time_duration: "",
        welcome_note: "",
        goodbye_note: "",
        intro_video: "",
        questions: [],
      },
      // questionData: [],
    };
  },
  computed: {
    ...mapState(["interview_questions"]),
  },
  methods: {
    addMoreQuestion() {
      if (!this.question)
        return this.$notify({
          group: "backend-error",
          title: "Error",
          text: "Please add the question",
          type: "error",
          duration: 5000,
        });
      const obj = {
        title: this.question,
        question_type: "audio/video",
        time_duration: "60",
        screen_share: true,
        reading_time: "60",
        possibleAnswers: [
          { text: "", correct: false }, // Add answers array for MCQs
        ],
        code_input: "",
      };
      this.form.questions.push(obj);
      this.question = "";
    },
    saveCodeInput(i, e) {
      this.form.questions[i].code_input = e;
    },
    removeQuestion(index) {
      this.form.questions.splice(index, 1);
    },
    addMoreOption(questionIndex) {
      this.form.questions[questionIndex].possibleAnswers.push({
        text: "",
        correct: false,
      });
    },

    removeOption(questionIndex, optionIndex) {
      this.form.questions[questionIndex].possibleAnswers.splice(optionIndex, 1);
    },
    onCancelClick() {
      localStorage.removeItem("questionId");
      this.$router.push("/setting/interview-questions");
    },
    onSaveButtonClick() {
      this.saveChangesPopUp = true;
      this.$refs.form.validate();
      if (!this.isValid)
        return this.$notify({
          group: "backend-error",
          title: "Error",
          text: "Some fields are required.",
          type: "error",
          duration: 5000,
        });
      if (this.form.questions.length === 0)
        return this.$notify({
          group: "backend-error",
          title: "Error",
          text: "Questions are required please add atleast one",
          type: "error",
          duration: 5000,
        });
    },
    getPossibleAnswers(possibleAnswers) {
      return possibleAnswers.map((answer) => {
        return answer.text;
      });
    },
    getCorrectAnswer(possibleAnswers) {
      return possibleAnswers.filter((answer) => {
        return answer.correct === true;
      });
    },
    async saveQuestion() {
      this.loading = true;
      this.payload = { ...this.form };
      this.payload.questions = this.payload.questions.map((question, index) => {
        question.reading_time = parseInt(question.reading_time).toString();
        question.time_duration = parseInt(question.time_duration).toString();
        question.order_no = index + 1;
        if (question.question_type === "mcq") {
          question.answers = this.getPossibleAnswers(question.possibleAnswers);
          question.correct_answer =
            this.getCorrectAnswer(question.possibleAnswers) &&
            this.getCorrectAnswer(question.possibleAnswers)[0]["text"];
        }
        if (question.question_type === "code") {
          question.title = `${question.title},${question.code_input}`;
        }
        delete question.code_input;
        delete question.possibleAnswers;
        return question;
      });
      try {
        const resp = await this.axios.post("/interview", this.payload);
        this.$notify({
          group: "backend-error",
          title: "Success",
          text: resp.data.data,
          type: "success",
          duration: 5000,
        });
        localStorage.removeItem("questionId");
        this.$router.push("/setting/interview-questions");
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    getPrefillPayloadForMCQ(answers, correctAnswer) {
      return answers.map((answer) => {
        return {
          text: answer,
          correct: answer === correctAnswer ? true : false,
        };
      });
    },
    async loadQuestionsIfFound() {
      let interviewId = localStorage.getItem("questionId");
      if (interviewId && this.interview_questions.length > 0) {
        let matchedQuestion = this.interview_questions.filter(
          (question) => question._id === interviewId
        );
        let formattedInterviewQuestions =
          matchedQuestion[0].interview_questions.map((question) => {
            return {
              order_no: question.order_no,
              question_type: question.question_type,
              reading_time: question.reading_time,
              screen_share: question.screen_share === "true" ? true : false,
              time_duration: question.time_duration,
              title:
                question.question_type === "code"
                  ? question.title.split(/,(.*)/s)[0]
                  : question.title,
              code_input:
                question.question_type === "code"
                  ? question.title.split(/,(.*)/s)[1]
                  : question.title,
              possibleAnswers:
                question.question_type === "mcq"
                  ? this.getPrefillPayloadForMCQ(
                      question.answers,
                      question.correct_answer
                    )
                  : [],
              correct_answer:
                question.question_type === "mcq" ? question.correct_answer : "",
            };
          });
        this.form.questions = formattedInterviewQuestions;
        this.form.title = matchedQuestion[0].title;
        this.form.time_duration = matchedQuestion[0].time_duration;
        this.form.welcome_note = matchedQuestion[0].welcome_note;
        this.form.goodbye_note = matchedQuestion[0].goodbye_note;
        this.form.intro_video = matchedQuestion[0].intro_video;
      }
    },
  },

  created() {
    this.loadQuestionsIfFound();
  },
};
</script>

<style lang="scss" scoped>
.v-input {
  margin-top: 0;
  padding-top: 0;
}
.intro-main {
  padding: 10px 20px;
  // display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #eaeef2;
  border-bottom: 1px solid grey;
  margin-bottom: 1rem;
}
.intro-main div {
  font-size: 14px;
  color: #232323;
  padding: 14px 5px 5px 5px;
  font-weight: 400;
  margin: 0 10px;
  // background-color: #fff;
}
.intro-main div:first-child,
.intro-main div:last-child {
  background: none;
}
@media (max-width: 767px) {
  .intro-main {
    // flex-direction: column;
  }
  .intro-main div {
    margin-bottom: 10px;
  }
  .intro-main div:last-child {
    margin-bottom: 0;
  }
}
.vue-html5-editor {
  z-index: 1 !important;
  .toolbar {
    z-index: 2 !important;
    ul {
      .icon {
        font-size: 1em;
      }
    }
  }
}
.code-editor {
  font-family: "Courier New", monospace;
}
</style>
